import { Suspense } from 'react'
import { Spinner } from '@chakra-ui/react'

import type { ChildrenProps } from '../../types/types'

export const SuspenseFullPage: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center flex-grow text-2xl">
          <Spinner size="lg" />
        </div>
      }
    >
      {children}
    </Suspense>
  )
}
