import React, { lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const AboutPage = lazy(() => import('./pages/About'))
const HomePage = lazy(() => import('./pages/Home'))
const ContactPage = lazy(() => import('./pages/Contact'))
const TestimonialsPage = lazy(() => import('./pages/Testimonials'))
const ClassPage = lazy(() => import('./pages/Class'))
const InstructorsPage = lazy(() => import('./pages/Instructors'))
const NotFoundPage = lazy(() => import('./pages/NotFound'))
const PricingPage = lazy(() => import('./pages/Pricing'))
const SchedulePage = lazy(() => import('./pages/Schedule'))

const Application: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/testimonials" element={<TestimonialsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/classes" element={<ClassPage />} />
        <Route path="/instructors" element={<InstructorsPage />} />
        <Route path="/schedule" element={<SchedulePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Application
