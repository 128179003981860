import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { i18n as i18nInstance, TFunction } from 'i18next'

import '../../i18n/config'

import type { ChildrenProps } from '../../types/types'

export type I18ContextType = {
  t: TFunction<'translation'>
  language: string
  i18n: i18nInstance
  changeLanguage: (lang: string) => void
}

export const I18Context = React.createContext<I18ContextType>({} as I18ContextType)

export const I18Provider: React.FC<ChildrenProps> = ({ children }) => {
  const langFromQuery = new URL(window.location.href).searchParams.get('lang') || 'en'
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState<string>(langFromQuery)

  const changeLanguage = (lang: string): void => {
    i18n.changeLanguage(lang, function () {
      setLanguage(lang)
    })
  }

  return (
    <>
      <I18Context.Provider
        value={{
          t,
          language,
          i18n,
          changeLanguage
        }}
      >
        {children}
      </I18Context.Provider>
    </>
  )
}

export const useI18Context = (): I18ContextType => useContext(I18Context)
